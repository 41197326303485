<template>
  <div>
    <b-card title="New Admin">
      <validation-observer ref="newAdminForm">
        <b-row>
          <b-col cols="3">
            <b-form-group label="First Name">
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  placeholder="Enter First Name"
                  v-model="newAdmin.firstname"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Last Name">
              <b-form-input
                placeholder="Enter Last Name"
                v-model="newAdmin.lastname"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Email">
              <validation-provider
                #default="{ errors }"
                name="Email ID"
                rules="required|email"
              >
                <b-form-input
                  placeholder="Enter Email ID"
                  v-model="newAdmin.email"
                ></b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="User Role">
              <b-form-select v-model="newAdmin.role" :options="adminRoles" />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <div class="text-right">
        <b-button @click="createAdmin" variant="primary">
          <b-spinner v-if="creatingAdmin" small></b-spinner> Create User
          <feather-icon icon="PlusIcon"></feather-icon>
        </b-button>
      </div>
    </b-card>
    <b-card no-body>
      <b-table
        :busy="loading"
        show-empty
        :items="admins"
        :fields="fields"
        striped
        responsive
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>Loading data...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center">
            <b-col cols="4" class="mx-auto my-2">
              <h4>No Data Found</h4>
              <feather-icon size="32" icon="AlertTriangleIcon"></feather-icon>
            </b-col>
          </div>
        </template>

        <template #cell(order_id)="data">
          <b-badge variant="light-warning">
            {{ data.item.orderID }}
          </b-badge>
        </template>

        <template #cell(name)="data">
          {{ data.item.firstname }}
          <span v-if="data.item.lastname">{{ data.item.lastname }}</span>
        </template>

        <template #cell(brand)="data">
          {{ data.item.brand.name }}
        </template>

        <template #cell(model)="data">
          {{ data.item.model.name }}
        </template>

        <template #cell(action)="data">
          <b-button variant="outline-success" class="btn-icon">
            <feather-icon icon="EditIcon" />
          </b-button>
          <b-button
            @click="deleteAdmin(data.item._id, 0)"
            variant="outline-primary"
            class="btn-icon ml-1"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </template>
      </b-table>

      <b-card-footer v-if="admins.length">
        <b-pagination
          :per-page="perPage"
          v-model="page"
          :total-rows="rows"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BCardFooter,
  BPagination,
  BSpinner,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from "bootstrap-vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BCardFooter,
    BPagination,
    BSpinner,
    BFormGroup,
    BFormInput,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
      fields: ["name", "email", "role", "action"],
      admins: [],
      page: 1,
      rows: 8,
      perPage: 15,
      loading: true,
      adminRoles: [
        { value: null, text: "Please select a Role" },
        { value: "Admin", text: "Admin" },
        { value: "Employee", text: "Employee" },
        { value: "Content Manager", text: "Content Manager" },
      ],
      newAdmin: {
        firstname: "",
        lastname: "",
        email: "",
        role: null,
      },
      creatingAdmin: false,
      deletingAdminIndex: null,
    };
  },
  watch: {
    page() {
      this.getAdmins();
    },
  },
  methods: {
    delay(delay) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, delay);
      });
    },
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getAdmins() {
      this.loading = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/admin?page=${this.page}&limit=${this.perPage}&status=active`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.admins);
          this.admins = response.data.admins;
          this.rows = response.data.count;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.loading = false;
        });
    },
    createAdmin() {
      this.$refs.newAdminForm.validate().then((success) => {
        if (success) {
          this.creatingAdmin = true;
          if (!this.newAdmin.role) {
            return this.makeToast(
              "danger",
              "Error",
              `Select the user role to continue!`
            );
          }
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/api/admin/add-admin`,
              this.newAdmin,
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then(async (response) => {
              this.creatingAdmin = false;
              this.makeToast(
                "success",
                "Success",
                `User has been created successfully!`
              );
              await this.delay(800);
              window.location.reload();
            })
            .catch((error) => {
              this.creatingAdmin = false;
              this.makeToast("danger", "Error", `Error adding user!`);
              console.log(error);
            });
        }
      });
    },
    deleteAdmin(userID, index) {
      if (confirm("Are you sure want to delete this user?")) {
        this.deletingAdminIndex = index;
        axios
          .delete(`${process.env.VUE_APP_SERVER_URL}/api/admin/${userID}`, {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          })
          .then(async (response) => {
            this.deletingAdminIndex = null;
            this.makeToast(
              "success",
              "Success",
              `User has been deleted successfully!`
            );
            await this.delay(800);
            window.location.reload();
          })
          .catch((error) => {
            this.deletingAdminIndex = null;
            this.makeToast("danger", "Error", `Error deleting user!`);
            console.log(error);
          });
      }
    },
  },
  mounted() {
    this.getAdmins();
  },
};
</script>
